import isEmpty from 'lodash.isempty';
import { findKeyByValue, formatCurrency, isNotEmpty } from './generalUtils';
import { fetchCategories } from '~/app/[locale]/home/store/requests';
import {
  CategoryType,
  ParamValueType,
  ProductCategoryBEnum,
  ProductCategoryFEnum,
  ProductPriceType,
  ProductType,
  SupportedCategoryType,
  ValueMappingType,
} from '~/types-and-enums/storeTypes';
import { ProductCategory } from './claimUtils';
import { Filters } from '~/atoms';
import { parameterIDs } from '~/constants/store-parameter-ids';
import { IconName } from '~/components/core/Icon';

export const valueMapping = {
  [ProductCategoryBEnum.Modules]: ProductCategoryFEnum.Modules,
  [ProductCategoryBEnum.Batteries]: ProductCategoryFEnum.Batteries,
  [ProductCategoryBEnum.Inverters]: ProductCategoryFEnum.Inverters,
  [ProductCategoryBEnum.ElectricCables]: ProductCategoryFEnum.Cables,
};

export const getOnlySupportedCategories = (allCategories: CategoryType[]) => {
  const supportedCategoryNames = Object.keys(valueMapping);

  const supportedCategories = allCategories
    .map((category) => {
      if (supportedCategoryNames.includes(category.name))
        return {
          value: valueMapping[category.name as keyof typeof valueMapping],
          id: category.id,
        };
    })
    .filter((item) => item !== undefined);
  return supportedCategories;
};

export const getSanitizedCategoryName = (
  name?: string | null
): string | null => {
  if (!name) return null;
  const typedValueMapping: ValueMappingType = valueMapping;
  return typedValueMapping[name] ?? null;
};

/**
 * Sets parameters based on the searchParams object.
 *
 * @param {Object} searchParams - An object containing key-value pairs for parameters.
 * @return {string} The concatenated string of parameters for the URL.
 */
export const setParameters = (searchParams: {
  [key: string]: string | undefined;
}) => {
  if (!searchParams || Object.keys(searchParams).length === 0) return '';

  let str = '';

  for (const [key, value] of Object.entries(searchParams)) {
    if (!value) continue; // Skip undefined or empty values

    if (key === 'query') {
      // Do nothing for `query` as per the original code
      continue;
    } else if (key === 'sort') {
      str += `&${key}=${value}`;
    } else if (key === 'category') {
      const allValues = value.split(',');
      let categoryNames = '';
      allValues.forEach((val) => {
        const categoryKey = findKeyByValue(valueMapping, val);
        if (categoryKey) {
          categoryNames +=
            categoryNames.length > 0 ? `;${categoryKey}` : categoryKey;
        }
      });

      str += `&${encodeURIComponent('category.name=')}${encodeURIComponent(
        categoryNames
      )}`;
    } else if (key === 'page') {
      str += `&${key}=${+value - 1}`;
    } else {
      // Use "key=value" format for all other parameters
      str += `&${key}=${value}`;
    }
  }
  if (str.includes('&Brand')) {
    str = str.replace(/&Brand=([^&]*)/, (_, brands) => {
      // Replace commas with semicolons in the existing Brand parameter
      return `&parameter=Brand:${brands.replace(/,/g, ';')}`;
    });
  }
  return str;
};

export const findSelectedCategory = async (categoryName: string) => {
  const categories = await fetchCategories();
  const selectedCategory = categories?.find(
    (category: SupportedCategoryType) => category.value === categoryName
  );

  return selectedCategory || null;
};

export const returnPriceString = (price: number, quantity?: number) => {
  const calculatedPrice = quantity ? price * quantity : price;
  return formatCurrency(calculatedPrice);
};

export const isOnlySearch = (allParams: {
  [key: string]: string | undefined;
}) => {
  const array = Object.keys(allParams);
  return array.length === 1 && array[0] === 'query';
};

export const getByParameter = ({
  array,
  param,
}: {
  array?: ParamValueType[];
  param: string;
}): string => {
  if (!array || !Array.isArray(array) || array.length === 0 || !param) {
    return '';
  }
  const env = process.env.NEXT_PUBLIC_ENV?.toLocaleLowerCase() ?? 'dev';
  // @ts-ignore
  const paramIds = parameterIDs?.[param as any]?.[env];

  const paramObject = array.find((item) =>
    paramIds?.includes(item.parameterId)
  );

  if (!paramObject || !paramObject?.value) {
    return '';
  }
  return paramObject ? paramObject.value : '';
};

export const mapProductsForResultList = (productsArray: ProductType[]) => {
  return isNotEmpty(productsArray)
    ? productsArray.map((product) => {
        return {
          id: product.id,
          name: product.name,
          brand: product.parameterValues
            ? getByParameter({ array: product.parameterValues, param: 'Brand' })
            : '',
          category: {
            id: product.category.id,
            name: product.category.name as ProductCategory,
          },
          productImage: {
            value: product.product_image?.value,
          },
          price: product.price.base_price,
          feedback: product.price.feedback,
        };
      })
    : [];
};

export const calculateUnitPrices = (
  measurements?: ParamValueType[],
  unitPrice?: string
) => {
  let pricePerUnit = 0;
  if (unitPrice) {
    pricePerUnit = parseFloat(unitPrice);
  }
  const unitsPerContainer = getByParameter({
    array: measurements,
    param: 'units_per_container',
  });

  const unitsPerPallet = getByParameter({
    array: measurements,
    param: 'units_per_pallet',
  });

  const unitsPerContainerValue = unitsPerContainer
    ? parseInt(unitsPerContainer, 10)
    : 0;

  const unitsPerPalletValue = unitsPerPallet ? parseInt(unitsPerPallet, 10) : 0;

  return {
    price: {
      unit: pricePerUnit,
      piece: pricePerUnit,
      pallet: pricePerUnit * unitsPerPalletValue,
      container: pricePerUnit * unitsPerContainerValue,
    },
  };
};

export const isAnyFilterSet = (filters: Filters) => {
  return Object.entries(filters).some(([key, value]) => {
    if (key === 'priceRange') {
      return (
        Array.isArray(value) &&
        value.length === 2 &&
        value.every((num) => typeof num === 'number')
      );
    }
    return value !== undefined;
  });
};

export const moveLongestValueToEnd = (array?: ParamValueType[]) => {
  if (isEmpty(array) || !array) return null;

  const longestValueObject = array.reduce((acc, curr) => {
    const accLength = acc?.value?.length ?? 0;
    const currLength = curr?.value?.length ?? 0;

    if (currLength > accLength) {
      return curr;
    }
    return acc;
  }, array[0]);

  // 45 is ~ two lines
  if ((longestValueObject?.value?.length ?? 0) <= 45) {
    return array;
  }

  const indexToRemove = array.indexOf(longestValueObject);
  if (indexToRemove !== -1) {
    array.splice(indexToRemove, 1);
  }

  array.push(longestValueObject);
  return array;
};

/**
 * Returns icon, name and link for each category.
 */
export const categoryValues = {
  [ProductCategoryFEnum.Inverters]: {
    icon: IconName.Inverters,
    name: 'inverters',
    link: ProductCategoryFEnum.Inverters + '?sort=popularity,desc',
  },
  [ProductCategoryFEnum.Modules]: {
    icon: IconName.Modules,
    name: 'modules',
    link: ProductCategoryFEnum.Modules + '?sort=popularity,desc',
  },
  [ProductCategoryFEnum.Batteries]: {
    icon: IconName.Batteries,
    name: 'batteries',
    link: ProductCategoryFEnum.Batteries + '?sort=popularity,desc',
  },
  [ProductCategoryFEnum.Cables]: {
    icon: IconName.Cables,
    name: 'cables',
    link: ProductCategoryFEnum.Cables + '?sort=popularity,desc',
  },
};

export const storeCategories = [
  {
    icon: IconName.Modules,
    name: 'navigation.modules',
    link: ProductCategoryFEnum.Modules + '?sort=popularity,desc',
  },
  {
    icon: IconName.Inverters,
    name: 'navigation.inverters',
    link: ProductCategoryFEnum.Inverters + '?sort=popularity,desc',
  },
  {
    icon: IconName.Batteries,
    name: 'navigation.batteries',
    link: ProductCategoryFEnum.Batteries + '?sort=popularity,desc',
  },
  {
    icon: IconName.Cables,
    name: 'navigation.cables',
    link: ProductCategoryFEnum.Cables + '?sort=popularity,desc',
  },
];

export const iconList = [
  { icon: 'batteries', name: 'PV Batteries' },
  { icon: 'modules', name: 'PV Modules' },
  { icon: 'inverters', name: 'PV Inverters' },
  { icon: 'cables', name: 'PV Cables' },
] as const;

export { ProductCategoryFEnum };

/**
 * Returns the unit object for a given parameter.
 * @param informationArray - measurements of the products (array of objects)
 * @param parameter - parameter to get the unit for
 */
export const getUnitsByParameter = (
  informationArray:
    | ProductType['measurements']
    | ProductType['main_parameters'],
  parameter: string
) => {
  return informationArray?.find((info) => info.parameter === parameter);
};

/**
 * Returns the message to show to the customer based on the error.
 * @param isShippingAddressInvalid - is the shipping address invalid
 * @param isPriceExpired - is the price expired
 * @param isProductOutOfStock - is the product out of stock
 * @param isSelectedQuantityNotAvailable - is the selected quantity not available
 * @param isSelectedWeekNotAvailable - is the selected week not available
 * @returns
 */
export const getErrorMessageForCustomer = ({
  isShippingAddressInvalid,
  isPriceExpired,
  isProductOutOfStock,
  isSelectedQuantityNotAvailable,
  isSelectedWeekNotAvailable,
  noAvailablePrice,
  isLogisticsCostZero,
  isPriceZero,
}: {
  isShippingAddressInvalid: boolean;
  isPriceExpired: boolean;
  isProductOutOfStock: boolean;
  isSelectedQuantityNotAvailable: boolean;
  isSelectedWeekNotAvailable: boolean;
  noAvailablePrice?: boolean;
  isLogisticsCostZero?: boolean;
  isPriceZero?: boolean;
}) => {
  // This order of showing the error messages is important
  switch (true) {
    case isShippingAddressInvalid:
      return 'page.shop.product-details.enter-valid-delivery-address';
    case noAvailablePrice || isPriceZero || isLogisticsCostZero:
      return 'page.store.the-product-currently-has-no-available-price';
    case isPriceExpired:
      return 'page.shop.product-details.the-price-has-expired';
    case isProductOutOfStock:
      return 'page.shop.product-details.product-is-out-of-stock';
    case isSelectedQuantityNotAvailable:
      return 'page.shop.product-details.there-are-only-max-pieces-available';
    case isSelectedWeekNotAvailable:
      return 'page.shop.product-details.product-is-only-available-from-week';
    default:
      return '';
  }
};

/**
 * Returns true if the price is not available.
 * - If all logistics cost values are zero
 * - If feedback.ERR_NO_MARGIN_FOUND is not null or undefined
 * - If selected_pq_price_expiration_date is undefined, null, or an invalid date
 * @param priceEngineData
 * @returns boolean
 */
export const isPriceNotAvailable = (priceEngineData: ProductPriceType) => {
  // true -> if all logistics cost values are zero
  const logistics_cost = priceEngineData?.logistics_cost;
  const allZeroLogistics = logistics_cost
    ? Object.values(logistics_cost).every((value) => value === 0)
    : false;

  // true -> if ERR_NO_MARGIN_FOUND has "" or something meaningful, other than null or undefined
  const noMarginAvailable =
    priceEngineData?.feedback?.ERR_NO_MARGIN_FOUND != null;

  // true -> if selected_pq_price_expiration_date is undefined, null, or an invalid date
  const expirationDate =
    priceEngineData?.aggregation?.selected_pq_price_expiration_date;
  const isExpirationDateInvalid =
    !expirationDate || new Date(expirationDate).toString() === 'Invalid Date';

  // Return true if any of the conditions are met
  return allZeroLogistics || noMarginAvailable || isExpirationDateInvalid;
};
