import { ProductCategory } from '~/utils/claimUtils';
import { IconName } from '~/components/core/Icon';
import { EmptyObjectType } from './generalTypes';
import { OrderItemProductType } from './ordersTypes';

export type BasePriceType = {
  unit?: number;
  pallet?: number;
  container?: number;
  metre?: number;
  piece?: number;
};

export type ProductpriceType = {
  discount?: {
    label: string;
    price: BasePriceType;
  };
} & BasePriceType;

export type RelatedLink = {
  href: string;
  templated: boolean;
};

export type RelatedLinks = {
  [key: string]: RelatedLink;
};

export type ParamValueType = {
  value?: string;
  numeric_value?: number;
  parameter?: string;
  parameterId?: string;
  unitSymbol?: string | null;
  unitSymbolRequired?: boolean;
  _links: RelatedLinks;
};

export type ProductPriceType = {
  id: string;
  version: string;
  aggregation: {
    total_quantity: number;
    available_quantity: number;
    earliest_delivery_date: string; // ISO date string
    latest_delivery_date: string; // ISO date string
    earliest_expiration_date: string; // ISO datetime string
    latest_expiration_date: string; // ISO datetime string
    selected_pq_price_expiration_date: string;
  };
  quantity: number;
  currency: string;
  selected_pqs: Record<string, any>[]; // Adjust based on actual object structure
  purchase_price: number;
  base_price: number;
  sales_price: number;
  margin_rate: number;
  subtotal: number;
  logistics_cost: {
    harbour: number;
    freight: number;
    warehouse: number;
    last_mile: number;
    pq: number;
    total: number;
  };
  delivery_cost_discounted?: boolean;
  delivery_cost: number;
  feedback: {
    AGGREGATED_VALUES: string;
    BASE_PRICE: string;
    FLOOR_PRICE: string;
    PURCHASE_PRICE: string;
    ERR_NOT_ENOUGH_AVAILABLE_QUANTITY?: string;
    ERR_NO_PQ_FOUND?: string;
    ERR_NO_MARGIN_FOUND?: string;
  };
};

export type KeyFeatureParameter =
  | 'Warranty'
  | 'Inverter Type'
  | 'Module Power'
  | 'Module Type'
  | 'Primary (Years, % Output Power)'
  | 'Panel Efficiency (%) At STC'
  | 'Cell Type'
  | 'Length'
  | 'Cable colour'
  | 'Colour'
  | 'Cable type'
  | 'Number of phases'
  | 'Inverter Power'
  | 'Capacity';

export type ParamValueTypeForKeyProperties = Omit<
  ParamValueType,
  'parameter'
> & {
  parameter: KeyFeatureParameter;
};

export type ProductType = {
  status?: string;
  id: string;
  salesforce_id: string;
  name: string;
  brand?: string;
  product_image?: ParamValueType;
  price: ProductPriceType;
  flag?: string;
  category: {
    id: ProductCategory | string;
    name: string;
  };
  keyFeatures?: ParamValueTypeForKeyProperties[];
  documents?: ParamValueType[];
  details?: ParamValueType[];
  measurements?: ParamValueType[];
  warrantyDetails?: ParamValueType[];
  warranty_details?: ParamValueType[];
  description?: ParamValueType;
  parameterValues?: ParamValueType[];
  deliveryDetails?: ParamValueType[];
  recommended?: boolean;
  main_parameters?: Array<EmptyObjectType>;
};

export enum ProductKeyproperties {
  ModuleType = IconName.ModuleType,
  OutputPower = IconName.OutputPower,
  PanelEfficiency = IconName.PanelEfficiency,
  CellType = IconName.CellType,
  Colour = IconName.Colour,
  CableType = IconName.CableType,
  NumberOfPhases = IconName.NumberOfPhases,
  InverterPower = IconName.InverterPower,
  MaxDCVoltage = IconName.MaxDCVoltage,
  AcPowerRange = IconName.AcPowerRange,
  BatteryVoltage = IconName.BatteryVoltage,
  Warranty = IconName.Warranty,
  Capacity = IconName.Capacity,
  Length = IconName.Length,
  InverterType = IconName.InverterType,
  ModulePower = IconName.ModulePower,
  CableColour = IconName.Cables,
}

export const KeyPropIconName = {
  de: {
    'Modul-Typ': ProductKeyproperties.ModuleType,
    'Primär (Jahre, % Ausgangsleistung)': ProductKeyproperties.OutputPower,
    'Wirkungsgrad des Panels (%) bei STC': ProductKeyproperties.PanelEfficiency,
    'Max. Gleichspannung': ProductKeyproperties.MaxDCVoltage,
    'AC-Leistungsbereich': ProductKeyproperties.AcPowerRange,
    'Battery Voltage': ProductKeyproperties.BatteryVoltage,
    Garantie: ProductKeyproperties.Warranty,
    Wechselrichtertyp: ProductKeyproperties.InverterType,
    Modulleistung: ProductKeyproperties.ModulePower,
    Zellentyp: ProductKeyproperties.CellType,
    Kabelfarbe: ProductKeyproperties.CableColour,
    Farbe: ProductKeyproperties.Colour,
    Kabeltyp: ProductKeyproperties.CableType,
    Phasenanzahl: ProductKeyproperties.NumberOfPhases,
    Wechselrichterleistung: ProductKeyproperties.InverterPower,
    Kapazität: ProductKeyproperties.Capacity,
    Länge: ProductKeyproperties.Length,
  },
  en: {
    'Inverter Type': ProductKeyproperties.InverterType,
    'Module Power': ProductKeyproperties.ModulePower,
    'Module Type': ProductKeyproperties.ModuleType,
    'Primary (Years, % Output Power)': ProductKeyproperties.OutputPower,
    'Panel Efficiency (%) At STC': ProductKeyproperties.PanelEfficiency,
    'Cell Type': ProductKeyproperties.CellType,
    'Cable colour': ProductKeyproperties.CableColour,
    'Cable type': ProductKeyproperties.CableType,
    'Number of phases': ProductKeyproperties.NumberOfPhases,
    'Inverter Power': ProductKeyproperties.InverterPower,
    'Max. DC voltage': ProductKeyproperties.MaxDCVoltage,
    'Series AC power range': ProductKeyproperties.AcPowerRange,
    'Battery Voltage': ProductKeyproperties.BatteryVoltage,
    Warranty: ProductKeyproperties.Warranty,
    Colour: ProductKeyproperties.Colour,
    Capacity: ProductKeyproperties.Capacity,
    Length: ProductKeyproperties.Length,
  },
};

/**
 * Returns product category names as used in the Backend
 * - Inverters = 'PV Inverters'
 * - Modules = 'PV Modules'
 * - Batteries = 'PV Batteries'
 * - Other = 'Other'
 */
export enum ProductCategoryBEnum {
  Inverters = 'PV Inverters',
  Modules = 'PV Modules',
  Batteries = 'PV Batteries',
  ElectricCables = 'Electric cables',
}

/**
 * Returns product category names as used in the Frontend
 * - Inverters = 'inverters'
 * - Modules = 'modules'
 * - Batteries = 'batteries'
 * - Other = 'cables'
 */
export enum ProductCategoryFEnum {
  Inverters = 'inverters',
  Modules = 'modules',
  Batteries = 'batteries',
  Cables = 'cables',
}

export type ProductCardType = {
  id: string;
  name: string;
  brand?: string;
  price?: number;
  category: {
    id: string;
    name?: ProductCategory | null;
  };
  flag?: string;
  productImage?: { value?: string };
  feedback: {
    ERR_NOT_ENOUGH_AVAILABLE_QUANTITY?: string;
    ERR_NO_MARGIN_FOUND?: string;
    ERR_NO_PQ_FOUND?: string;
  };
};

/**
 * Returns possible price units
 * - Container = 'container'
 * - Piece = 'piece'
 * - Metre = 'metre'
 * - UnitEnum = 'unit'
 * - Palet = 'pallet'
 */
export enum PriceUnitEnum {
  Container = 'container',
  Piece = 'piece',
  Metre = 'metre',
  UnitEnum = 'unit',
  Palet = 'pallet',
}

/**
 * Returns supported param values for sorting product data
 * - EarliestDelivery = 'earliestDelivery'
 * - LowToHigh = 'lowToHigh'
 * - HightToLow = 'highToLow'
 * - AtoZ = 'name,asc'
 * - Draft = 'Draft'
 */
export enum DropDownvaluesEnum {
  EarliestDelivery = 'earliestDelivery',
  LowToHigh = 'lowToHigh',
  HightToLow = 'highToLow',
  AtoZ = 'name,asc',
  ZtoA = 'name,desc',
  Draft = 'Draft',
}

export type CategoryType = {
  name: string;
  parent: {
    href: string;
    templated: boolean;
  } | null;
  id: string;
  children: [];
  _links: {
    [key: string]: string;
  };
};

export type SupportedCategoriesType = {
  [ProductCategoryFEnum.Batteries]: {
    id: string;
    value: string;
    label: string;
  };
  [ProductCategoryFEnum.Modules]: { id: string; value: string; label: string };
  [ProductCategoryFEnum.Inverters]: {
    id: string;
    value: string;
    label: string;
  };
  [ProductCategoryFEnum.Cables]: { id: string; value: string; label: string };
};

export type SupportedCategoryType = {
  value: string | ProductCategoryFEnum;
  id: string;
};

export type ValueMappingType = { [key: string]: string };

/**
 * Returns possible store access status
 * used to determine if the user has visited the coming soon page already and if he has activated the notification
 * - FirstVisited = 'firstVisited'
 * - ActivatedNotification = 'activatedNotification'
 * - VisitedAgain = 'visitedAgain'
 */
export enum StoreAccessStatusEnum {
  FirstVisited = 'firstVisited',
  ActivatedNotification = 'activatedNotification',
  VisitedAgain = 'visitedAgain',
}

export type CheckoutDetailsType = {
  id: string;
  sf_id: string;
  andercore_id: string;
  subtotal: number;
  delivery_cost: number;
  bnpl_credit_options: BNPLCreditOptionsType[];
  standard_payment_options: StandardPaymentOptionsType[];
  delivery_cost_discounted?: boolean;
};

export type StandardPaymentOptionsType = {
  payment_option_key: string;
  prepayment_percentage: number;
  prepayment_amount: number;
  after_delivery_percentage: number;
  after_delivery_amount: number;
  credit_period_in_days: number;
};

export type BNPLCreditOptionsType = {
  payment_option_key: string;
  credit_period_in_days: number;
  payment_due_window: string;
  is_best_value: boolean;
  is_most_popular: boolean;
  credit_option_fee: number;
  credit_option_percentage: number;
  showPaywall?: boolean;
};

export enum BNPLResponseStatusesEnum {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  UNKNOWN = 'UNKNOWN',
}
export type ExecutiveAccountType = {
  ownerId: string;
  andercoreId: string | number;
};

export type ShoppingCartOrderType = {
  sf_id: string;
  quote_number: string;
  account_name?: string;
  shipping_address: {
    street: string;
    city: string;
    country: string;
    postal_code: string;
    email?: string;
  };
  total_order_price: string;
  category: string;
  items: OrderItemProductType[];
  owner_id: string;
  andercore_id: string;
  delivery_cost: number;
  total_price: string;
  total_cogs_price: number;
  delivery_cost_discounted: boolean;
  isAddressValid: boolean;
  validationObject: object;
  items_merged?: boolean;
};

export type PriceEngineDataType = {
  product_key: string;
  product_id: string;
  reference_id: string;
  delivery: {
    destination: {
      postcode: string;
      country: string;
    };
    quantity: number;
    delivery_date: string;
  };
}[];

type PriceInfo = Record<string, ProductPriceType>;

export type PriceEngineResponseType = {
  price_info: PriceInfo;
  delivery_cost: number;
  delivery_cost_discounted: boolean;
  total_cost: number;
  total_goods_price: number;
};
